
import React from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    SelectInput,
    Filter,
    useListContext,
    TopToolbar,
    FilterButton,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    required,
    sanitizeListRestProps,
    SaveButton,
    DeleteButton,
    Toolbar,
    ArrayInput,
    RichTextField,
    SimpleFormIterator,
    NumberInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    SelectArrayInput,
    ReferenceField,
    ImageField,
    BooleanField,
    BooleanInput,
    SearchInput,
    DateTimeInput,
    FunctionField,
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import { useMediaQuery } from '@mui/material';
import Icon from '@mui/icons-material/Book'

export const MatchIcon = Icon

const filterArray = [
    <SearchInput source="search" alwaysOn />,
    <TextInput source="match_id" />,
    <TextInput label="Team" source="match_team" />,
    <TextInput label="Team ID" source="match_team_id" />,
    <TextInput label="Team1" source="match_team1" />,
    <TextInput label="Team1 ID" source="match_team1_id" />,
    <TextInput label="Team2" source="match_team2" />,
    <TextInput label="Team2 ID"  source="match_team2_id" />,
    <TextInput label="District" source="match_district" />,
    <TextInput label="League" source="match_league" />,
    <TextInput label="League ID" source="match_league_id" />,
    <TextInput label="Poule" source="match_poule" />,
    <TextInput label="Poule ID" source="match_poule_id" />,
    <TextInput label="Phase" source="match_phase" />,
    <TextInput label="Phase ID" source="match_phase_id" />,
    <TextInput label="Day" source="match_day" />,
    <TextInput label="Ground Type" source="match_ground_type" />,
    <DateTimeInput source="start" />,
    <DateTimeInput source="end" />,
]

const FilterList = (props) => (
    <Filter {...props}>
        {filterArray}
    </Filter>
)

const ListActions = (props) => {
    console.log(props)
    return (
        <TopToolbar>
            <FilterButton filters={filterArray} />
            {/* <CreateButton /> */}
            {/* <ExportButton/> */}
        </TopToolbar>
    );
}

export const MatchList = (props) => (
    <List {...props} filters={<FilterList />} actions={<ListActions />} perPage={50} pagination={<Pagination rowsPerPageOptions={[10, 50, 100, 200]} />}>
        {
            useMediaQuery(theme => theme.breakpoints.down('sm')) ? (
                <SimpleList linkType="show"
                    primaryText={record => record ? `${record.team1} - ${record.team2}` : ''}
                />
            ) : (
                <Datagrid>
                    <TextField source="match_id" sortable={false} />
                    <TextField source="day" sortable={true} />
                    <TextField source="district" sortable={true} />
                    <TextField source="league" sortable={true} />
                    <TextField source="poule" sortable={true} />
                    <TextField source="phase" sortable={false} />
                    <TextField source="team1" sortable={true} />
                    <TextField source="team2" sortable={true} />
                    <FunctionField label="Score" render={(record) => `${record.score1 !== null && record.score1 !== undefined ? record.score1 : ''}${record.forfeit1 ? ` (${record.forfeit1})` : ''} - ${record.score2 !== null && record.score2 !== undefined ? record.score2 : ''}${record.forfeit2 ? ` (${record.forfeit2})` : ''}`} />
                    {/* <TextField source="score1" sortable={false} />
                    <TextField source="score2" sortable={false} /> */}
                    <DateField source="date_value" showTime={true} sortable={true} />

                    <TextField source="terrain_id" sortable={false} />
                    <TextField source="latitude" sortable={false} />
                    <TextField source="longitude" sortable={false} />
                    <UrlField source="api" sortable={false} />

                    <DateField source="updatedAt" showTime={true} sortable={true} />


                    <DateField source="updatedAt_list" showTime={true}    sortable={false}  label={"Mise à jour par le scrapeur arborecense"} />
                    <DateField source="updatedAt_list_2" showTime={true}  sortable={false}  label={"Mise à jour par le scrapeur arborecense (précédemment)"} />
                    <DateField source="updatedAt_past" showTime={true}    sortable={false}  label={"Mise à jour par le scrapeur qui traite les matchs sans score les + anciens"} />
                    <DateField source="updatedAt_past_2" showTime={true}  sortable={false}  label={"Mise à jour par le scrapeur qui traite les matchs sans score les + anciens (précédemment)"} />
                    <DateField source="updatedAt_fast" showTime={true}    sortable={false}  label={"Mise à jour par le scrapeur de scoring"} />
                    <DateField source="updatedAt_fast_2" showTime={true}  sortable={false}  label={"Mise à jour par le scrapeur de scoring (précédemment)"} />

                    {/* <EditButton /> */}
                    <ShowButton />
                </Datagrid>
            )
        }
    </List>
)

export const MatchShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="match_id" />
            <DateField source="date_value" showTime={true} />
            <TextField source="day" />
            <TextField source="team1" />
            <TextField source="team1_id" />
            <ImageField source="img1" />
            <TextField source="team2" />
            <TextField source="team2_id" />
            <ImageField source="img2" />
            <TextField source="district" />
            <TextField source="league" />
            <TextField source="league_id" />
            <TextField source="ground_type" />
            <FunctionField label="Ground" render={(record) => (record.ground || []).join('\n')} />
            <FunctionField label="Infos" render={(record) => (record.infos || []).join('\n')} />
            <TextField source="score1" />
            <TextField source="score2" />
            <TextField source="forfeit1" />
            <TextField source="forfeit2" />
            <TextField source="poule" />
            <TextField source="poule_id" />
            <TextField source="phase" />
            <TextField source="phase_id" />
            <UrlField source="url" />
            <DateField source="createdAt" showTime={true} />
            <DateField source="updatedAt" showTime={true} />

            <DateField source="updatedAt_list" showTime={true}      label={"Mise à jour par le scrapeur arborecense"} />
            <DateField source="updatedAt_list_2" showTime={true}    label={"Mise à jour par le scrapeur arborecense (précédemment)"} />
            <DateField source="updatedAt_past" showTime={true}      label={"Mise à jour par le scrapeur qui traite les matchs sans score les + anciens"} />
            <DateField source="updatedAt_past_2" showTime={true}    label={"Mise à jour par le scrapeur qui traite les matchs sans score les + anciens (précédemment)"} />
            <DateField source="updatedAt_fast" showTime={true}      label={"Mise à jour par le scrapeur de scoring"} />
            <DateField source="updatedAt_fast_2" showTime={true}    label={"Mise à jour par le scrapeur de scoring (précédemment)"} />

        </SimpleShowLayout>
    </Show>
)

// export const MatchEdit = (props) => (
//     <Edit {...props}>
//         <SimpleForm redirect="show">
//             <TextInput source="sport" validate={required()} />
//             <TextInput source="country" validate={required()} />
//             <TextInput source="league" validate={required()} />
//             <TextInput source="team1" validate={required()} />
//             <TextInput source="team2" validate={required()} />
//             <DateTimeInput source="date" validate={required()} />
//             <NumberInput source="score1" />
//             <NumberInput source="score2" />
//             <TextField source="extra" />
//             <NumberInput source="bet1" />
//             <NumberInput source="bet2" />
//             <NumberInput source="betX" />
//         </SimpleForm>
//     </Edit>
// )

// export const MatchCreate = (props) => (
//     <Create {...props}>
//         <SimpleForm redirect="show">
//             <TextInput source="sport" validate={required()} />
//             <TextInput source="country" validate={required()} />
//             <TextInput source="league" validate={required()} />
//             <TextInput source="team1" validate={required()} />
//             <TextInput source="team2" validate={required()} />
//             <DateTimeInput source="date" validate={required()} />
//             <NumberInput source="score1" />
//             <NumberInput source="score2" />
//             <TextField source="extra" />
//             <NumberInput source="bet1" />
//             <NumberInput source="bet2" />
//             <NumberInput source="betX" />
//         </SimpleForm>
//     </Create>
// )
